import React, { useEffect, useState } from 'react'
import Section from "../common/Section";
import { useDispatch, useSelector } from 'react-redux';
import { init, setScan, setTotalPage } from '../store/file/fileSlice';
import { PTC } from '../helper/constant';
import Textbox from '../common/form/Textbox';
import ConfirmBtn from '../common/form/ConfirmBtn';
import { fetchUsers } from '../api/api';
import { arrange, arrangeTimeCard, extractAndSendPages } from '../helper/azur';
import TableV1 from '../common/TableV1';

const TimeCard = ({file}) => {
    const dispatch = useDispatch();
    const fileStore = useSelector( (store) => store.file );

    const [title, setTitle] = useState('Scanning...');
    const [err, setErr] = useState('')
    const [docFile, setDocFile] = useState(file);
    const [preventCall, setPreventCall] = useState(false)

    useEffect( () => {

        // Scannig Docs

        if(preventCall) return;
        setPreventCall(true);
        dispatch(setTotalPage(0))
        dispatch(setScan(0))
        // Scannig Docs
        extractAndSendPages({file: docFile,type: PTC}, (resData) => {
            const keys = ['employee_name', 'employee_id', 'total_hours', 'total_overtime_hours'];
            const tableKeys = ['DAY', 'RM_IN', 'RM_OUT','RE_IN', 'RE_OUT'];
            if(resData.length > 0) {
                // resData.forEach((data)=>{
                    arrangeTimeCard({keys: keys, tableKeys: tableKeys, records: resData}, (formatData) => {
                        fetchUsers(fileStore.workbook);
                        dispatch(init(formatData))
                        setTitle('Data Confirmation');
                    })
                // })
            }else{
                setTitle('Error !');
                setErr("Something went wrong!");
            }
        })

    },[docFile])

    useEffect(()=>{
        setTitle(`Scanning(${fileStore.scan}/${fileStore.totalPage})...`)
    },[fileStore.totalPage, fileStore.scan])

  return (
    <Section title={title}>
        {   err? err
            : fileStore.data? 
            <>
                <TableV1 />
                <ConfirmBtn doctype={PTC} data={fileStore} />
            </>: ''
        }    
      
        
        
    </Section>
  )
}

export default TimeCard
