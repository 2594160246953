import React, {useState} from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Section from "../common/Section";

import { useSelector, useDispatch } from 'react-redux'
import { init } from '../store/menu/menuSlice'

const Ocr = () => {
    useDispatch(init('ocr'))
    const [err, setErr] = useState('')
    const [response, setResponse] = useState('');
    const [image, setImage] = useState();

    const handleSubmit = async () => {
        if(image){
            const data = new FormData() 
            data.append('image', image);
            setErr("")
            axios({
                method: "post",
                url: "http://localhost:5000/ocr",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
                console.log(response.data);
                // setResponse(response.data)
                setImage('')
            })
            .catch((error) => {
              console.log(error);
            });
        }else{
            console.log("Error")
            setErr("Image required!")
        }
    }

    return (
        <>
        <Section title="OCR">
            <div className='col-md-12 mt-2 error' style={{"color":"red"}} > {err}</div>
            <Row>
                <div className='col-md-4'>
                    <FilePond 
                        onaddfile={(err,file) =>{
                            setImage(file.file);
                        }}
                        onremovefile={(err,file) =>{
                            setImage('');
                        }}
                    />
                </div> <br />
                {/* <h4>NCC Excel</h4> */}
            </Row>
            <Row>
                <Col lg={3}>
                    <Button variant="success" onClick={()=> handleSubmit()}>Scan Image</Button>
                </Col>

            </Row>
            <div className='col-md-12' style={{"color":"green"}}> {response}</div>
        </Section>
        
        </>
    )
}

export default Ocr