import React, { useEffect, useState } from 'react'
import Section from "../common/Section";
import { useDispatch, useSelector } from 'react-redux';
import { init } from '../store/file/fileSlice';
import { BPP } from '../helper/constant';
import Textbox from '../common/form/Textbox';
import ConfirmBtn from '../common/form/ConfirmBtn';
import { azur} from '../helper/azur';
import { fetchFileData, fetchUsers } from '../api/api';
import TableV1 from '../common/TableV1';

const Workbook = ({file, type}) => {
    const dispatch = useDispatch();
    const fileStore = useSelector( (store) => store.file );

    const [title, setTitle] = useState('Scanning...');
    const [err, setErr] = useState('')
    const [docFile, setDocFile] = useState(file);
    const [response, setResponse] = useState();

 
    
 
    useEffect( () => {

        // Scannig Docs
        const data = new FormData() 
        data.append('workbook', docFile);
        data.append('days', fileStore.days);
        data.append('type', type);
        setErr("")
        
        fetchFileData(data).then((resData)=>{
            if(resData.success){
                fetchUsers(fileStore.workbook);
                const obj = {
                    table: resData.data
                }
                dispatch(init(obj))
                setTitle('Data Confirmation');
            }else{
                setTitle('Error !');
                setErr("Something went wrong!");
            }
        })

    },[docFile])

  return (
    <Section title={title}>
        {   
            err? err
            : fileStore.data? <>
                <TableV1 />
                <ConfirmBtn />
            </>: ''
        }    
    </Section>
  )
}

export default Workbook


