import axios from "axios"
import { setWorkbooks, setUsers } from "../store/file/fileSlice";
import store from "../store/index"
import { BASEURL } from "../helper/constant";
const { dispatch } = store; 



export const getWorkbooks = () => {
    axios({
        method: "get",
        url: `${BASEURL}/workbooks`
    }).then((response) => {
        console.log(response.data);
        dispatch(setWorkbooks(response.data))
    })
}

export const uploadWorkbook = async (data) => {
    const response = await axios({
        method: "post",
        url:`${BASEURL}/workbooks/upload`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    })

    return response.data;
}

export const removeWorkbook = async (data) => {
    const response = await axios.delete(`${BASEURL}/workbooks/${data.name}`)
    return response.data;
}

// import


export const importData = async (data) => {
    const response = await axios({
        method: "post",
        url: `${BASEURL}/excel/import`,
        data: data,
    })

    return response.data;
}

// get Users 
export const fetchUsers = async (data) => {
    console.log("CALLED");
    axios({
        method: "post",
        url: `${BASEURL}/excel/users`,
        data: { workbook: data},
    })
    .then((response) => {
      // handle the response
        console.log(response.data);
        console.log("CALLED DATA", response.data.data);
        dispatch(setUsers(response.data.data))
    })
    .catch((error) => {
      // handle errors
      console.log(error);
    });
}

export const fetchFileData = async (data) => {
    const response = await axios({
        method: "post",
        url: `${BASEURL}/excel/getExcelData`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    })

    return response.data;
}