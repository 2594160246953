import { createSlice } from '@reduxjs/toolkit'

export const fileSlice = createSlice({
  name: 'file',
  initialState: {
    data: null,
    month: null,
    days: null,
    users: null,
    workbooks: [],
    workbook: "",
    totalPage: 0,
    scan: 0
  },
  reducers: {
    init: (state, action) => {
      state.data = action.payload;
    },
    update: (state, action) => {
      console.log(action.payload.arrkey);
      if(action.payload.arrkey !== undefined){
        console.log('TABLE UPDATE')
        if(state.data.table[action.payload.arrkey][action.payload.objkey] !== undefined) state.data.table[action.payload.arrkey][action.payload.objkey] = action.payload.value;
      }else{
        console.log('OBJECT UPDATE')
        if(state.data[action.payload.objkey] !== undefined) state.data[action.payload.objkey] = action.payload.value;
      }
    },
    setMonth: (state, action) => {
      const reqData = JSON.parse(action.payload);
      console.log(reqData);
      state.month = reqData.month;
      state.days = reqData.days;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setWorkbook: (state, action) => {
      state.workbook = action.payload
    },
    setWorkbooks: (state, action) => {
      state.workbooks = action.payload 
    },
    setScan: (state, action) => {
      state.scan = action.payload 
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload 
    }
  },
})

// Action creators are generated for each case reducer function
export const { init, update, setMonth, setUsers, setFiles, setWorkbook, setWorkbooks, setScan, setTotalPage} = fileSlice.actions

export default fileSlice.reducer