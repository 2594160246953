
export const FORM_RECOGNIZER_ENDPOINT = 'https://form-recognizer-540.cognitiveservices.azure.com/';
export const FORM_RECOGNIZER_API_KEY = '767dd54886454a4fbb94b60bc5e24a18';
export const BASEURL = "https://api.gssctimesheets.com"
// export const BASEURL = "http://localhost:5000"

// BPP
export const BPP = "Bisha Power Plant - BPP";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_BPP = 'BPP_TRAIN_1';

// Shuqaiq
export const SHU = "Shuqaiq Supply";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_SHU = 'TRAIN_MODEL_SHU';

// RPP
export const RPP = "Rabigh Power Plant - RPP";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_RPP = 'TRAIN_MODEL_RPP';

// SPP
export const SPP = "Seasonal Manpower Sec - SPP";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_SPP = 'TRAIN_MODEL_SEA';

// GSSE
export const GSSE = "GSSE";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_GSSE = 'TRAIN_MODEL_GSSE';

// SCAFFOLD
export const SCAFFOLD = "SCAFFOLD";

// NCC
export const NCC = "NCC";

// EQP
export const EQP = "EQP";

// YCC
export const YCC = "YCC";

// PTC
export const PTC = "Time Card";
export const FORM_RECOGNIZER_CUSTOM_MODEL_ID_PTC = 'TRAIN_MODEL_PTC';