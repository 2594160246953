import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { update } from '../../store/file/fileSlice';

const Textbox = (props) => {
    const dispatch = useDispatch();

    const handleChange = async (e) => {
        var field = props;
        field = {...field, value: e.currentTarget.value } ;
        console.log(field);
        dispatch(update(field))
    }

    return (
        <input type='text' value={props.value} onChange={(e)=>handleChange(e)} />
    )
}

export default Textbox