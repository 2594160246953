import React from 'react'
import axios from 'axios';
import store from '../store/index'
import { setUsers, setScan, setTotalPage } from '../store/file/fileSlice';
import { PDFDocument, rgb } from 'pdf-lib';
import { BPP, FORM_RECOGNIZER_API_KEY, FORM_RECOGNIZER_CUSTOM_MODEL_ID_BPP, FORM_RECOGNIZER_CUSTOM_MODEL_ID_GSSE, FORM_RECOGNIZER_CUSTOM_MODEL_ID_PTC, FORM_RECOGNIZER_CUSTOM_MODEL_ID_RPP, FORM_RECOGNIZER_CUSTOM_MODEL_ID_SHU, FORM_RECOGNIZER_CUSTOM_MODEL_ID_SPP, FORM_RECOGNIZER_ENDPOINT, GSSE, PTC, RPP, SHU, SPP } from './constant';
const { AzureKeyCredential, DocumentAnalysisClient } = require("@azure/ai-form-recognizer");
const {dispatch} = store;

export const azur = async (data, callback) => {

    const endpoint = FORM_RECOGNIZER_ENDPOINT;
    const credential = new AzureKeyCredential(FORM_RECOGNIZER_API_KEY);
    const client = new DocumentAnalysisClient(endpoint, credential);
    const modelId = 
        data.type == BPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_BPP : 
        data.type == SHU ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_SHU :
        data.type == RPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_RPP :
        data.type == SPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_SPP :
        data.type == GSSE ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_GSSE : false;

    if(!modelId) callback(false);
    
    const poller = await client.beginAnalyzeDocument(
        modelId,
        data.file
    );
    
    const {
        documents: [document],
    } = await poller.pollUntilDone();
    
   
    if (!document) {
        throw new Error("Expected at least one document in the result.");
    }

    console.log("Docs",document)
    
    callback(document.fields);
    
}

 const azurAsync = async (data) => {

    const endpoint = FORM_RECOGNIZER_ENDPOINT;
    const credential = new AzureKeyCredential(FORM_RECOGNIZER_API_KEY);
    const client = new DocumentAnalysisClient(endpoint, credential);
    const modelId = 
        data.type == BPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_BPP : 
        data.type == SHU ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_SHU :
        data.type == RPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_RPP :
        data.type == SPP ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_SPP :
        data.type == GSSE ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_GSSE : 
        data.type == PTC ? FORM_RECOGNIZER_CUSTOM_MODEL_ID_PTC : false;

    if(!modelId) return false;
    
    const poller = await client.beginAnalyzeDocument(
        modelId,
        data.file
    );
    
    const {
        documents: [document],
    } = await poller.pollUntilDone();
    
   
    if (!document) {
        throw new Error("Expected at least one document in the result.");
    }

    // console.log("Docs",document)
    return document.fields;
    
}

export const extractAndSendPages = async (data, callback) => {
    const pages = [];

    try{
        const pdfDoc = await PDFDocument.load(await data.file.arrayBuffer());
        
        if (pdfDoc.getPageCount() === 0) {
            throw new Error("The PDF document does not contain any pages.");
        }

        const resArr = [];
        dispatch(setTotalPage(pdfDoc.getPageCount()))
        for (let i = 0; i < pdfDoc.getPageCount(); i++) {
            const newPdfDoc = await PDFDocument.create();
            const [existingPage] = await newPdfDoc.copyPages(pdfDoc, [i])
            newPdfDoc.addPage(existingPage); // Use an array with the page to copy
            const docFile = await newPdfDoc.save();
       
    
            const resData = await azurAsync({file: docFile,type: data.type})
            console.log("RES:",resData)
            dispatch(setScan(i+1))
            if(resData) resArr.push(resData);
      
        }
        
        await Promise.all(resArr);
        callback(resArr);
        
        
       
    }catch(err){
        console.error(`Error: ${err.message}`);
        callback([]);
    }
   
    // return resArr;
  }

// FOR BPP, SPP, RPP

export const arrange = async (data, callback) => {
    const { keys, tableKeys, records} = data;
    const formatData = {};
    keys.forEach( mainKey => {
        formatData[mainKey]  = records[mainKey]? records[mainKey].value : '';
    })

    const tableArr = records.table.values.map( (item, i) => {
        if(item.properties){
            let row = {};
            for(const [key, subitem] of Object.entries(item.properties)){
                row[key] = subitem.value; 
            }

            // tableKeys.forEach( key => {
            //     if(!row[key]) row[key] = '' ;
            // })
            // filter row
            let filterRow = {};
           
            return row;
        }
    })

    formatData.table = tableArr;

    callback(formatData);
}

export const arrangeCommon = async (data, callback) => {
    const { keys, tableKeys, records} = data;
    const formatData = {};
    formatData.table = []
    records.forEach((record)=> {
        keys.forEach( mainKey => {
            formatData[mainKey]  = record[mainKey]? record[mainKey].value : '';
        })

        let filterTable = {};
        filterTable['NAME'] = formatData['employee_name'];
        filterTable['ID'] = formatData['id'];
        filterTable['TOTAL'] =  formatData['total_hours']? getInt(formatData['total_hours']) : '';
        filterTable['OT_TOTAL'] = formatData['total_overtime_hours']? getInt(formatData['total_overtime_hours']) : '';
        filterTable['ASSIGN'] = { name : "" };
        record.table.values.forEach( (item, i) => {
            if(item.properties){
                let row = {};   
                for(const [key, subitem] of Object.entries(item.properties)){
                    row[key] = subitem.value; 
                }
                tableKeys.forEach( key => {
                    if(!row[key]) row[key] = '' ;
                })
                // console.log("ROW: ", row);
                if(row['R_HRS']){
                    // console.log("R_HRS: ",row['R_HRS'])
                    filterTable[i+1] = getInt(row['R_HRS']);
                }else{
                    filterTable[i+1] = '';
                }
                
            }
        } )

        formatData.table.push(filterTable);
    })

    callback(formatData);
}   
    
export const arrangeTimeCard = async (data, callback) => {
    const { keys, tableKeys, records} = data;
    const formatData = {};
    formatData.table = [];
    records.forEach((record)=> {
        keys.forEach( mainKey => {
            formatData[mainKey]  = record[mainKey]? record[mainKey].value : '';
        })

        let filterTable = {};
        filterTable['NAME'] = formatData['employee_name'];
        filterTable['ID'] = formatData['employee_id'];
        filterTable['TOTAL'] =  formatData['total_hours']? getInt(formatData['total_hours']) : '';
        filterTable['OT_TOTAL'] = formatData['total_overtime_hours']? getInt(formatData['total_overtime_hours']) : '';
        filterTable['ASSIGN'] = { name : "" };
        record.table.values.forEach( (item, i) => {
            if(item.properties){
                let row = {};   
                for(const [key, subitem] of Object.entries(item.properties)){
                    row[key] = subitem.value; 
                }
                tableKeys.forEach( key => {
                    if(!row[key]) row[key] = '' ;
                })
                // console.log("ROW: ", row);
                if(row['RM_IN'] && row['RE_OUT']){
                    // console.log("R_HRS: ",row['R_HRS'])
                    if(row['RM_IN'].toString().includes('-') || row['RE_OUT'].toString().includes('-')) return filterTable[i+1] = '';
                    filterTable[i+1] = 8;
                }else{
                    filterTable[i+1] = '';
                }
                
            }
        } )

        formatData.table.push(filterTable);
    })

    callback(formatData);
}


// FOR GSSE

export const arrangeGSSE = async (data, callback) => {
    const { keys, tableKeys, records, fileStore} = data;
    const formatData = {};

    keys.forEach( mainKey => {
        formatData[mainKey]  = records[mainKey]? records[mainKey].value : '';
    })


    const tableArr = records.table.values.map( (item, i) => {
        if(item.properties){
            let row = {};
            for(const [key, subitem] of Object.entries(item.properties)){
                row[key] = subitem.value; 
            }
            return row;
        }
    })

    let table = [], type = '', tempObj = {}, rc = 1;
    // tempObj.NORMAL = {}
    // tempObj.OT = {}

    tableArr.forEach( (item, i) => {
        
        if(Object.keys(item).length == 1) return type = item.SN;
        
        if(item.SN !== undefined) tempObj.SN = item.SN ;
        if(item.NAME !== undefined) tempObj.NAME = item.NAME ;
        if(item.SKILL_TYPE !== undefined) tempObj.SKILL_TYPE = item.SKILL_TYPE ;
        if(item.TOTAL_HRS !== undefined) tempObj.TOTAL_HRS = item.TOTAL_HRS ;
        tempObj.TYPE = type ;
        if(item.NRM_HRS !== undefined) tempObj.TOTAL= item.NRM_HRS ;
        if(item.OT_HRS !== undefined) tempObj.OT_TOTAL= item.OT_HRS ;
        item['ASSIGN'] = { name : "" };

        if(item.POSITION.toLowerCase().includes('normal') && item.POSITION.toLowerCase().includes('ot')){

            if(tempObj.TOTAL == undefined) tempObj.TOTAL= "" ;
            if(tempObj.OT_TOTAL == undefined) tempObj.OT_TOTAL= "" ;
            
            // Array(fileStore.days).fill(0).map( (x,days) => {
            //     tempObj.NORMAL[days+1] = item[days+1] !== undefined ? item[days+1] : '';
            //     tempObj.OT[days+1] = '';
            // })
            Array(fileStore.days).fill(0).forEach( (x, days) => {
                tempObj[days+1] = item[days+1] !== undefined ? item[days+1] : '';
            })

            tableKeys.forEach( key => {
                if(!tempObj[key]) tempObj[key] = '' ;
            })

            // Push to main array
            table.push(tempObj)
            tempObj = {}

        }else{
            

            if(item.POSITION.toLowerCase().includes('normal')){
                // tempObj.NORMAL = {};
                Array(fileStore.days).fill(0).forEach( (x,days) => {
                    tempObj[days+1] = item[days+1] !== undefined ? item[days+1] : '';
                })
            }
            // if(item.POSITION.toLowerCase().includes('ot')){
            //     // tempObj.OT = {};
            //     Array(fileStore.days).fill(0).map( (x,days) => {
            //         tempObj[days+1] = item[days+1] !== undefined ? item[days+1] : '';
            //     })
            // }

            if(rc == 2){

                if(tempObj.TOTAL == undefined) tempObj.TOTAL= "" ;
                if(tempObj.OT_TOTAL == undefined) tempObj.OT_TOTAL= "" ;

                tableKeys.forEach( key => {
                    if(!tempObj[key]) tempObj[key] = '' ;
                })

                // Push to main array
                table.push(tempObj)
                tempObj = {}
                rc=1;

            }else{
                rc=2;
            }
        }

    })
    
    // tableKeys.forEach( key => {
    //     if(!row[key]) row[key] = '' ;
    // })
    // if(Object.keys(item.properties).length == 1) return type = item.properties.SN : '';

    formatData.table = table;

    console.log(formatData);

    callback(formatData);
}


// Function to extract numbers

// function getInt(str) {
 
 
//     // Using match with regEx
//     let matches = str.match(/(\d+)/);
//     return matches? matches[0] : str;
// }

const getInt = (str) => {
    const matches = str.match(/(\d+)/);
    return matches? matches[0] : str;
}

