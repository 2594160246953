
// @ts-nocheck
import React, {useEffect, useState} from 'react'
import Bpp from './Bpp'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Section from "../common/Section";

import { useSelector, useDispatch } from 'react-redux'
import { init } from '../store/menu/menuSlice'
import { BPP, EQP, GSSE, NCC, PTC, RPP, SCAFFOLD, SHU, SPP, YCC } from '../helper/constant';
import { init as initFile, setMonth, setWorkbook } from '../store/file/fileSlice';
import Shu from './Shu';
import Rpp from './Rpp';
import Spp from './Spp';
import Gsse from './Gsse';

// get json month data
import months from '../helper/month.json'
import Workbook from './Workbook';
import TimeCard from './TimeCard';

const Dashboard = () => {
    const dispatch = useDispatch();
    dispatch(init('dashboard'))
    const fileStore = useSelector((state)=> state.file);
    const [err, setErr] = useState('')
    const [docFile, setDocFile] = useState('');
    const [docType, setDocType] = useState('');
    const [scan, setScan] = useState(false);

    const handleSubmit = async () => {
      (docFile && fileStore.month)? setScan(true) : setErr("Fields required!");
    }



  return (
    <>
        <Section title="Dashboard">
            <div className='col-md-12 mt-2 error' style={{"color":"red"}} > {err}</div>
           
            <Row>
                <div className='col-md-4'>
                  
                <select className='custom-field' name='workbook' id="workbook" onChange={(e)=>dispatch(setWorkbook(e.target.value))} >
                    <option value="">--- Select Main Workbook ---</option>
                    {
                        fileStore.workbooks && fileStore.workbooks.map((item)=>
                            <option value={item.name}>{item.name}</option>
                        )
                    }
                </select> <br/>
                  <select className='custom-field' name='doc_type' id="doc_type" onChange={(e)=>setDocType(e.currentTarget.value)} >
                    <option>--- Select Document Type ---</option>
                    <option value={BPP}>{BPP}</option>
                    <option value={SHU}>{SHU}</option>
                    <option value={RPP}>{RPP}</option>
                    <option value={SPP}>{SPP}</option>
                    <option value={PTC}>{PTC}</option>
                    {/* <option value={GSSE}>{GSSE}</option> */}
                    <option value={SCAFFOLD}>{SCAFFOLD}</option>
                    <option value={NCC}>{NCC}</option>
                    <option value={EQP}>{EQP}</option>
                    <option value={YCC}>{YCC}</option>

                  </select> <br/>
                  <select className='custom-field' name='month' id="month" 
                    onChange={(e)=>{
                      setScan(false)
                      dispatch(setMonth(e.currentTarget.value))
                    }} 
                  >
                    <option>--- Select Document Month ---</option>
                    {
                      months.map( item => 
                        <option value={JSON.stringify(item)}>{item.month.concat(' ', `( ${item.days} )`)}</option>
                      )
                    }
                  </select> <br/>
                    <FilePond 
                        onaddfile={(err,file) =>{
                          setDocFile(file.file);
                          dispatch(initFile(''))
                        }}
                        onremovefile={(err,file) =>{
                          setDocFile('');
                          dispatch(initFile(''))
                          setScan(false)
                        }}
                    />
                </div> <br />
                {/* <h4>NCC Excel</h4> */}
            </Row>
            
            <Row>
              <Col lg={3}>
                  <Button variant="success" 
                    onClick={()=> handleSubmit()} 
                    disabled={ (docFile && fileStore.month && !scan && fileStore.workbook)? false : true }
                  >Scan File</Button>
              </Col>
            </Row>
            
            
        </Section>

        {
          scan? 
            docType == BPP ? <Bpp file={docFile}/> :
            docType == SHU ? <Shu file={docFile} /> :  
            docType == RPP ? <Rpp file={docFile} /> :  
            docType == SPP ? <Spp file={docFile} /> : 
            docType == PTC ? <TimeCard file={docFile} /> : 
            // docType == GSSE ? <Gsse file={docFile} /> : 
            docType == SCAFFOLD ? <Workbook file={docFile} type={docType} /> : 
            docType == NCC ? <Workbook file={docFile} type={docType} /> : 
            docType == EQP ? <Workbook file={docFile} type={docType} /> : 
            docType == YCC ? <Workbook file={docFile} type={docType} /> : 
            ''
          : ''
        }
    </>
  )
}

export default Dashboard