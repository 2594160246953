import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { update } from '../../store/file/fileSlice';

const Search = (props) => {
    const dispatch = useDispatch()
    const items = useSelector( (store) => store.file.users );

    const [filter, setFilter] = useState([]);
    console.log(props);

    const filterItem = (e) => {
        // console.log(items);
        const search = e.target.value;
        
        // update store ASSIGN object
        var field = props;
        field = {...field, value: { name: e.currentTarget.value }} ;
        dispatch(update(field))

        if(search === '') {
            setFilter([]);
        }else{
            // Filter array
            console.log(items);
            const newItems = items.filter(item=>{
                if(item.name.toLowerCase().includes(search.toLowerCase())) return item
                if(item.uid.toLowerCase().includes(search.toLowerCase())) return item
            }).sort();

            // Set filtered value
            setFilter(newItems);

        }
    }

  const setValue = (item) => {
    // update store ASSIGN object
    var field = props;
    field = {...field, value: item} ;
    dispatch(update(field))

    setFilter([]);
  }

  return (
    <div className='dropdown'>
        <input
            type="text"
            className={props.value.row? "back-green" : ""}
            placeholder={props.placeholder}
            value={props.value.name}
            onChange={(e)=>filterItem(e)}
            disabled={props.isInputDisable}
        />
        <div className='drop-down-list'>
            <ul>{
                    filter.map((item,index)=>
                         <li key={index} value={item} onClick={()=>setValue(item)}>{item.name}</li>
                    )
                }
            </ul>
        </div>
    </div>
  )
}

export default Search