import { configureStore } from '@reduxjs/toolkit'
import menuReducer from './menu/menuSlice'
import fileReducer from './file/fileSlice'

export default configureStore({
  reducer: {
    menu: menuReducer,
    file: fileReducer
  },
  
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})