import React, { useEffect, useState } from 'react'
import Section from "../common/Section";
import { useDispatch, useSelector } from 'react-redux';
import { init, setScan, setTotalPage } from '../store/file/fileSlice';
import { RPP } from '../helper/constant';
import Textbox from '../common/form/Textbox';
import ConfirmBtn from '../common/form/ConfirmBtn';
import { fetchUsers } from '../api/api';
import { arrange, arrangeCommon, azur, extractAndSendPages } from '../helper/azur';
import TableV1 from '../common/TableV1';

const Rpp = ({file}) => {
    const dispatch = useDispatch();
    const fileStore = useSelector( (store) => store.file );

    const [title, setTitle] = useState('Scanning...');
    const [err, setErr] = useState('')
    const [docFile, setDocFile] = useState(file);
    const [preventCall, setPreventCall] = useState(false)

    useEffect( () => {

        // Scannig Docs

        if(preventCall) return;
        setPreventCall(true);
        dispatch(setTotalPage(0))
        dispatch(setScan(0))
        // Scannig Docs
        extractAndSendPages({file: docFile,type: RPP}, (resData) => {
            const keys = ['acting_manager', 'contract_no', 'employee_name', 'id', 'iqama', 'position', 'section_head', 'stage', 'total_hours', 'total_overtime_hours'];
            const tableKeys = ['DAY','DATE', 'R_IN', 'R_OUT', 'R_HRS', 'O_IN', 'O_OUT', 'O_HRS', 'UNIT', 'JR_NO'];
            if(resData.length > 0) {
                // resData.forEach((data)=>{
                    arrangeCommon({keys: keys, tableKeys: tableKeys, records: resData}, (formatData) => {
                        fetchUsers(fileStore.workbook);
                        dispatch(init(formatData))
                        setTitle('Data Confirmation');
                    })
                // })
            }else{
                setTitle('Error !');
                setErr("Something went wrong!");
            }
        })

    },[docFile])

    useEffect(()=>{
        setTitle(`Scanning(${fileStore.scan}/${fileStore.totalPage})...`)
    },[fileStore.totalPage, fileStore.scan])

  return (
    <Section title={title}>
        {   err? err
            : fileStore.data? 
            <>
                <TableV1 />
                <ConfirmBtn doctype={RPP} data={fileStore} />
            </>: ''
        }    
      
        
        
    </Section>
  )
}

export default Rpp

{/*
<table cellPadding={10} >
                    <tr>
                        <th>Contract No:</th>
                        <td><Textbox value={fileData.contract_no} key="contract_no" objkey="contract_no" /></td>
                        <th>Iqama:</th>
                        <td><Textbox value={fileData.iqama} key="iqama" objkey="iqama" /></td>
                        <th>Stage:</th>
                        <td><Textbox value={fileData.stage} key="stage" objkey="stage" /></td>
                    </tr>
                    <tr>
                        <th>Employee Name:</th>
                        <td><Textbox value={fileData.employee_name} key="employee_name" objkey="employee_name" /></td>
                        <th>Position:</th>
                        <td><Textbox value={fileData.position} key="position" objkey="position" /></td>
                        <th>ID:</th>
                        <td><Textbox value={fileData.id} key="id" objkey="id" /></td>
                    </tr>
                </table>
                <table cellPadding={10} >
                    <tr>
                        <th rowSpan={2}>DAY</th>
                        <th rowSpan={2}>DATE</th>
                        <th colSpan={3}>Regular Time</th>
                        <th colSpan={3}>Over Time</th>
                        <th rowSpan={2}>UNIT</th>
                        <th rowSpan={2}>JR_NO</th>
                    </tr>
                    <tr>
                        <th>IN</th>
                        <th>OUT</th>
                        <th>HOURS</th>
                        <th>IN</th>
                        <th>OUT</th>
                        <th>HOURS</th>
                    </tr>
                    {
                        fileData.table.map( (tableItem, index) => 
                            <tr key={index}>
                                <td><Textbox value={tableItem.DAY} key="DAY" objkey="DAY" arrkey={index} /></td>
                                <td><Textbox value={tableItem.DATE} key="DATE" objkey="DATE" arrkey={index} /></td>
                                <td><Textbox value={tableItem.R_IN} key="R_IN" objkey="R_IN" arrkey={index} /></td>
                                <td><Textbox value={tableItem.R_OUT} key="R_OUT" objkey="R_OUT" arrkey={index} /></td>
                                <td><Textbox value={tableItem.R_HRS} key="R_HRS" objkey="R_HRS" arrkey={index} /></td>
                                <td><Textbox value={tableItem.O_IN} key="O_IN" objkey="O_IN" arrkey={index} /></td>
                                <td><Textbox value={tableItem.O_OUT} key="O_OUT" objkey="O_OUT" arrkey={index} /></td>
                                <td><Textbox value={tableItem.O_HRS} key="O_HRS" objkey="O_HRS" arrkey={index} /></td>
                                <td><Textbox value={tableItem.UNIT} key="UNIT" objkey="UNIT" arrkey={index} /></td>
                                <td><Textbox value={tableItem.JR_NO} key="JR_NO" objkey="JR_NO" arrkey={index} /></td>
                            </tr>
                        )
                    }
                    <tr>
                        <th colSpan={3}>TOTAL NUMBER OF HOURS:</th>
                        <td colSpan={2}><Textbox value={fileData.total_hours} key="total_hours" objkey="total_hours" /></td>
                        <th colSpan={3}>TOTAL OVERTIME HOURS:</th>
                        <td colSpan={2}><Textbox value={fileData.total_overtime_hours} key="total_overtime_hours" objkey="total_overtime_hours" /></td>
                    </tr>
                    <tr>
                        <th colSpan={3}>SECTION HEAD:</th>
                        <td colSpan={2}><Textbox value={fileData.section_head} key="section_head" objkey="section_head" /></td>
                        <th colSpan={3}>ACTING MANAGER:</th>
                        <td colSpan={2}><Textbox value={fileData.acting_manager} key="acting_manager" objkey="acting_manager" /></td> 
                    </tr>
                    
                </table>
                <ConfirmBtn doctype={RPP} data={fileData} />
*/}