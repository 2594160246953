import React from 'react'
import { Col, Container } from 'react-bootstrap'
import Excel from '../pages/Excel'
import Ocr from '../pages/Ocr'
import '../css/Main.css'
import {
  Routes,
  Route,
} from 'react-router-dom';
import Dashboard from '../pages/Dashboard'
import Files from '../pages/Files'


const Main = () => {
  return (
    <Col className='main' xs={10}>
        <Routes>
            <Route exact path='/' element={<Dashboard/>}></Route>
            <Route exact path='/ocr' element={<Ocr/>}></Route>
            <Route exact path='/excel' element={<Excel/>}></Route>
            <Route exact path='/files' element={<Files/>}></Route>
        </Routes>
    </Col>
  )
}

export default Main