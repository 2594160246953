import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { init } from '../store/menu/menuSlice'
import Section from '../common/Section'

registerPlugin(FilePondPluginImagePreview)

const Excel = () => { 
    useDispatch(init('excel'))
    const [err, setErr] = useState('')
    const [response, setResponse] = useState('')
    const [formData, setFormData] = useState({
        workbook: '',
        start: 0,
        end: 0
      });

    const handleSubmit = async () => {

        if(formData.workbook && formData.start && formData.end){
            const data = new FormData() 
            data.append('file', formData.workbook);
            data.append('start', formData.start);
            data.append('end', formData.end);
            console.log("Success")
            setErr("")
            axios({
                method: "post",
                url: "http://localhost:5000/excel",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            })
            // .post("http://localhost:5000/excel", data, {
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   },
            // })
            .then((response) => {
              // handle the response
              console.log(response.data);
            //   setResponse(response)
              setFormData({
                    workbook: '',
                    start: 0,
                    end: 0
                });
            })
            .catch((error) => {
              // handle errors
              console.log(error);
            });

           
        }else{
            console.log("Error")
            setErr("All fields required!")
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

    return (
        <>
         <Section  title="NCC Excel"> 
            <div className='col-md-12 mt-2 error' style={{"color":"red"}} > {err}</div>
            <Row>
                <div className='col-md-4'>
                    <FilePond 
                        onaddfile={(err,file) =>{
                            setFormData({ ...formData, workbook: file.file });
                        }}
                        onremovefile={(err,file) =>{
                            setFormData({ ...formData, workbook: '' });
                        }}
                    />
                </div> <br />
            </Row>
            <Row>
                {/* <Col lg={3}>
                    <Form.Control placeholder="Starting row number" name="start" value={formData.start} onChange={(e)=> handleChange(e)} required />
                </Col>
                <Col lg={3}>
                    <Form.Control placeholder="Ending row number " name="end" value={formData.end} onChange={(e)=> handleChange(e)} required />
                </Col> */}
                <Col lg={3}>
                    <Button variant="success" onClick={()=> handleSubmit()}>Import Rows</Button>
                </Col>

            </Row>
            <div className='col-md-12' style={{"color":"green"}}> {response}</div>
        </Section>
        </>
    )
}

export default Excel;