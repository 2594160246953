import React from 'react'
import Container from 'react-bootstrap/Container';
import Sidebar from './common/Sidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Main from './common/Main';
import './App.css'
import './css/Main.css'


const App = () => {
  return (
    <>  
        <Container fluid>
            <Row> 
                <Sidebar />
                <Main />
            </Row>
        </Container>
    </>
  )
}

export default App