import React, { useEffect, useState } from 'react'
import Section from "../common/Section";
import { useDispatch, useSelector } from 'react-redux';
import { init, setScan, setTotalPage } from '../store/file/fileSlice';
import { BPP } from '../helper/constant';
import Textbox from '../common/form/Textbox';
import ConfirmBtn from '../common/form/ConfirmBtn';
import { azur, extractAndSendPages} from '../helper/azur';
import { fetchUsers } from '../api/api';
import TableV1 from '../common/TableV1';

const Bpp = ({file}) => {
    const dispatch = useDispatch();
    const fileStore = useSelector( (store) => store.file );

    const [title, setTitle] = useState('Scanning...');
    const [err, setErr] = useState('')
    const [docFile, setDocFile] = useState(file);
    const [response, setResponse] = useState();

    const [preventCall, setPreventCall] = useState(false);
 
    
 
    useEffect( () => {
        if(preventCall) return;
        setPreventCall(true);
        dispatch(setTotalPage(0))
        dispatch(setScan(0))
        // Scannig Docs

        extractAndSendPages({file: docFile,type: BPP}, (resData) => {
            
            let table = [];
            if(resData.length > 0) {

                resData.forEach( (data) => {
                    let m = 0;
                    const tableArr = data.TABLE1.values.map( (item, i) => {
                        if(i % 2 == 0) m = (i == 0)? 1 : (i/2)+1 ;
                        
                        if(item.properties){
                            let row = {};
                            for(const [key, subitem] of Object.entries(item.properties)){
                                row[key] = subitem.value; 
                            }
                            row.M = m;
                            return row;
                        }
                    })

                    // Merge row data

                    let last, start = 0;
                    Array(tableArr.length/2).fill(0).map((x, day) => {
                        last = start + 2;
                        let tmpArr = tableArr.slice(start, last);
                            // if(tmpArr[0].NUMBER){
                            //     tmpArr[1].NUMBER = tmpArr[0].NUMBER
                            // }
                            // if(tmpArr[1].NUMBER){
                            //     tmpArr[0].NUMBER = tmpArr[1].NUMBER
                            // }
                        Array(fileStore.days).fill(0).map( (x,days) => {
                            if(tmpArr[0][days+1]){
                                tmpArr[0][days+1] = tmpArr[0][days+1].includes('X')? 'X' : 
                                tmpArr[0][days+1].includes('V')? 'V' : 
                                tmpArr[0][days+1].includes('H')? 'H' : 
                                tmpArr[0][days+1].includes('S')? 'S' : 
                                tmpArr[0][days+1].includes('Z')? 'Z' : 
                                tmpArr[0][days+1];
                            }else{
                                tmpArr[0][days+1] = '';
                            }
                            if(tmpArr[1]['TOTAL']){
                                tmpArr[0]['OT_TOTAL'] = tmpArr[1]['TOTAL'];
                            //     tmpArr[1][days+1] = tmpArr[1][days+1].includes('X')? 'X' : 
                            //     tmpArr[1][days+1].includes('V')? 'V' : 
                            //     tmpArr[1][days+1].includes('H')? 'H' : 
                            //     tmpArr[1][days+1].includes('S')? 'S' : 
                            //     tmpArr[1][days+1].includes('Z')? 'Z' : 
                            //     tmpArr[1][days+1];
                            // }else{
                            //     tmpArr[1][days+1] = '';
                            }
                            tmpArr[0]['ASSIGN'] = { name : "" };
                        })
                        table.push(tmpArr[0]);
                        start = last;
                    })

                })
                
                // Final data
                const formatData = {
                    // activity: data.activity.value,
                    // sector: data.sector.value,
                    // business_district: data.business_district.value,
                    // contract_name: data.contract_name.value,
                    // contract_no: data.contract_no.value,
                    // contractor_name: data.contractor_name.value,
                    // month: data.month.value,
                    // year: data.year.value,
                    table: table
                }
                fetchUsers(fileStore.workbook);
                dispatch(init(formatData))
                
                setTitle(`Data Confirmation ${fileStore.month} (${fileStore.days})`);

                
            }else{
                setTitle('Error !');
                setErr("Something went wrong!");
            }
        })

        // azur({file: docFile,type: BPP}, (data) => {
        //     if(data){
        //         // Formate response

        //         let m = 0;
        //         const tableArr = data.TABLE1.values.map( (item, i) => {
        //             if(i % 2 == 0) m = (i == 0)? 1 : (i/2)+1 ;
                    
        //             if(item.properties){
        //                 let row = {};
        //                 for(const [key, subitem] of Object.entries(item.properties)){
        //                     row[key] = subitem.value; 
        //                 }
        //                 row.M = m;
        //                 return row;
        //             }
        //         })


        //         // Merge row data

        //         let last, start = 0;
        //         let table = [];
        //         Array(tableArr.length/2).fill(0).map((x, day) => {
        //             last = start + 2;
        //             let tmpArr = tableArr.slice(start, last);
        //                 // if(tmpArr[0].NUMBER){
        //                 //     tmpArr[1].NUMBER = tmpArr[0].NUMBER
        //                 // }
        //                 // if(tmpArr[1].NUMBER){
        //                 //     tmpArr[0].NUMBER = tmpArr[1].NUMBER
        //                 // }
        //             Array(fileStore.days).fill(0).map( (x,days) => {
        //                 if(tmpArr[0][days+1]){
        //                     tmpArr[0][days+1] = tmpArr[0][days+1].includes('X')? 'X' : 
        //                     tmpArr[0][days+1].includes('V')? 'V' : 
        //                     tmpArr[0][days+1].includes('H')? 'H' : 
        //                     tmpArr[0][days+1].includes('S')? 'S' : 
        //                     tmpArr[0][days+1].includes('Z')? 'Z' : 
        //                     tmpArr[0][days+1];
        //                 }else{
        //                     tmpArr[0][days+1] = '';
        //                 }
        //                 if(tmpArr[1]['TOTAL']){
        //                     tmpArr[0]['OT_TOTAL'] = tmpArr[1]['TOTAL'];
        //                 //     tmpArr[1][days+1] = tmpArr[1][days+1].includes('X')? 'X' : 
        //                 //     tmpArr[1][days+1].includes('V')? 'V' : 
        //                 //     tmpArr[1][days+1].includes('H')? 'H' : 
        //                 //     tmpArr[1][days+1].includes('S')? 'S' : 
        //                 //     tmpArr[1][days+1].includes('Z')? 'Z' : 
        //                 //     tmpArr[1][days+1];
        //                 // }else{
        //                 //     tmpArr[1][days+1] = '';
        //                 }
        //                 tmpArr[0]['ASSIGN'] = { name : "" };
        //             })
        //             table.push(tmpArr[0]);
        //             start = last;
        //         })

        //         // const filteredTable = table.filter( (x) => {
        //         //     console.log(x);
        //         //     if(x.TYPE == 'عادى') return x;
        //         // } )

        //         // console.log("Table: ",filteredTable);
        //         // Final format

        //         const formatData = {
        //             // activity: data.activity.value,
        //             // sector: data.sector.value,
        //             // business_district: data.business_district.value,
        //             // contract_name: data.contract_name.value,
        //             // contract_no: data.contract_no.value,
        //             // contractor_name: data.contractor_name.value,
        //             // month: data.month.value,
        //             // year: data.year.value,
        //             table: table
        //         }
        //         fetchUsers(fileStore.workbook);
        //         dispatch(init(formatData))
                
        //         setTitle(`Data Confirmation ${fileStore.month} (${fileStore.days})`);
        //     }else{
        //         setTitle('Error !');
        //         setErr("Something went wrong!");
        //     }
        // })

    },[docFile])

    useEffect(()=>{
        setTitle(`Scanning(${fileStore.scan}/${fileStore.totalPage})...`)
    },[fileStore.totalPage, fileStore.scan])


  return (
    <Section title={title}>
        {   
            err? err
            : fileStore.data? <>
                <TableV1 />
                <ConfirmBtn doctype={BPP} data={fileStore} />
            </>: ''
        }    
    </Section>
  )
}

export default Bpp




{/* <table cellPadding={10} >
                    <tr>
                        <th>Activity:</th>
                        <td><Textbox value={fileStore.activity} key="activity" objkey="activity" /></td>
                        <th>Sector:</th>
                        <td><Textbox value={fileStore.sector} key="sector" objkey="sector" /></td>
                        <th>Business District:</th>
                        <td><Textbox value={fileStore.business_district} key="business_district" objkey="business_district" /></td>
                    </tr>
                    <tr>
                        <th>Contract Name:</th>
                        <td><Textbox value={fileStore.contract_name} key="contract_name" objkey="contract_name" /></td>
                        <th>Contract No:</th>
                        <td><Textbox value={fileStore.contract_no} key="contract_no" objkey="contract_no" /></td>
                        <th>Contractor Name:</th>
                        <td><Textbox value={fileStore.contractor_name} key="contractor_name" objkey="contractor_name" /></td>
                    </tr>
                    
        </table> 
        <table cellPadding={10} >
        <tr>
            <th>M</th>
            <th>NUMBER</th> 
            <th className='columnName'>NAME</th>
            <th>TYPE</th>
            { Array(fileStore.days).fill(0).map((x, day) => 
                <th>{day+1}</th>
            )}
            <th>TOTAL</th>
            <th>OT TOTAL</th>
        </tr>
        
        {
            fileStore.data.table.map( (tableItem, index) => 
                <>
                <tr>
                <td><Textbox value={tableItem.M} key="M" objkey="M" arrkey={index} /></td>
                <td><Textbox value={tableItem.NUMBER} key="NUMBER" objkey="NUMBER" arrkey={index} /></td>
                <td><Textbox value={tableItem.NAME} key="NAME" objkey="NAME" arrkey={index} /></td>
                <td><Textbox value={tableItem.TYPE} key="TYPE" objkey="TYPE" arrkey={index} /></td>
                { Array(fileStore.days).fill(0).map((x, day) => 
                    <td>
                        <Textbox value={tableItem[day+1]} key={day+1} objkey={day+1} arrkey={index} />
                    </td>
                )}
                <td><Textbox value={tableItem.TOTAL} key="TOTAL" objkey="TOTAL" arrkey={index} /></td>
                <td><Textbox value={tableItem.OT_TOTAL} key="OT_TOTAL" objkey="OT_TOTAL" arrkey={index} /></td>
                </tr>
                </>
            )
        }
    </table>
    <ConfirmBtn doctype={BPP} data={fileStore} />
*/}
