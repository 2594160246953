import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import '../css/Sidebar.css'
import { useSelector, useDispatch } from 'react-redux'
import { init } from '../store/menu/menuSlice'
import { Link } from 'react-router-dom'
import { getWorkbooks } from '../api/api';

const Sidebar = () => {

    const dispatch = useDispatch();
    const menu = useSelector( (state) => state.menu)

    useEffect(()=>{
        getWorkbooks()
    },[])

    return (
        <Col xs={2} className='sidebar'>
            <div className="brand">
                <h2>Admin</h2>
            </div>
            <Nav className="flex-column">
                <Link to="/"  onClick={()=>dispatch(init('dashboard'))} className={menu.active == 'dashboard' ? menu.class : ''}>Dashboard</Link>
                {/* <Link to="/ocr"  onClick={()=>dispatch(init('ocr'))} className={menu.active == 'ocr' ? menu.class : ''}>OCR</Link> */}
                {/* <Link to="/excel"  onClick={()=>dispatch(init('excel'))} className={menu.active == 'excel' ? menu.class : ''}>Excel</Link> */}
                <Link to="/files"  onClick={()=>dispatch(init('files'))} className={menu.active == 'files' ? menu.class : ''}>Files</Link>
            </Nav>
        </Col>
    )
}

export default Sidebar