import React from 'react'
import Textbox from './form/Textbox';
import { useDispatch, useSelector } from 'react-redux';
import Search from './form/Search';

const TableV1 = () => {
  
  const dispatch = useDispatch();
  const fileStore = useSelector( (store) => store.file );

  return (
    <>
      <table cellPadding={10} >
        <tr>
            {/* <th>M</th>
            <th>NUMBER</th> */}
            <th className='columnName'>NAME</th>
            <th className='columnName'>ASSIGN</th>
            {/* <th>TYPE</th> */}
            { Array(fileStore.days).fill(0).map((x, day) => 
                <th>{day+1}</th>
            )}
            <th>TOTAL</th>
            <th>OT TOTAL</th>
        </tr>
        
        {
            fileStore.data.table.map( (tableItem, index) => 
                <>
                <tr key={index}>
                {/* <td><Textbox value={tableItem.M} key="M" objkey="M" arrkey={index} /></td>
                <td><Textbox value={tableItem.NUMBER} key="NUMBER" objkey="NUMBER" arrkey={index} /></td> */}
                <td><Textbox value={tableItem.NAME} key="NAME" objkey="NAME" arrkey={index} /></td>
                <td><Search value={tableItem.ASSIGN} key="ASSIGN" objkey="ASSIGN" arrkey={index} /></td>
                {/* <td><Textbox value={tableItem.TYPE} key="TYPE" objkey="TYPE" arrkey={index} /></td> */}
                { Array(fileStore.days).fill(0).map((x, day) => 
                    <td>
                        <Textbox value={tableItem[day+1]} key={day+1} objkey={day+1} arrkey={index} />
                    </td>
                )}
                <td><Textbox value={tableItem.TOTAL} key="TOTAL" objkey="TOTAL" arrkey={index} /></td>
                <td><Textbox value={tableItem.OT_TOTAL} key="OT_TOTAL" objkey="OT_TOTAL" arrkey={index} /></td>
                </tr>
                </>
            )
        }
    </table>
    </>
  )
}

export default TableV1