import React from 'react'
import '../css/Section.css'

const Section = ({title, children}) => {
  return (
    <div className="section">
        <h4>{title}</h4>
        <hr />
        {children}
    </div>
  )
}

export default Section