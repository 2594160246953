import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { importData } from '../../api/api';
import { setWorkbook } from '../../store/file/fileSlice';


const ConfirmBtn = (props) => {
    // props.data | props.doctype 
    const dispatch = useDispatch()
    const [response, setResponse] = useState('');
    const [disabled, setDisabled] = useState(true);
    const fileStore = useSelector( (store) => store.file );

    const handleSubmit = () => {
        console.log(fileStore.workbook, fileStore.data?.table)

        const reqObj = {
            table: fileStore.data.table,
            workbook: fileStore.workbook
        }
        
        importData(reqObj).then((resData) => {
            console.log(resData)
            setResponse(resData)
        })

        // setResponse("Data imported to the Main sheet")
    }

    useEffect(()=>{
        console.log("DATA", fileStore.workbook,fileStore.data?.table?.length)
        if(fileStore.workbook && fileStore.data?.table?.length > 0 ) {
            console.log("FOUND")
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    },[fileStore])

    console.log(response)
    return (
        <>  <br/>
            <div className='import-action'>
                <Button variant="success" onClick={()=> handleSubmit()} disabled={disabled}>Import</Button>
            </div>
            <br/>
            <p>{response?.message}</p>
            {response?.rowIds && response?.rowIds.map( resId => 
               <p>{resId}</p>
            )}
        </>
    )
}

export default ConfirmBtn