import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { init } from '../store/menu/menuSlice'
import Section from '../common/Section'
import { getWorkbooks, removeWorkbook, uploadWorkbook } from '../api/api';
import { BASEURL } from '../helper/constant';

registerPlugin(FilePondPluginImagePreview)

const Files = () => { 
    useDispatch(init('excel'))
    const [err, setErr] = useState('')
    const [response, setResponse] = useState('')
    const [workbook, setWorkbook] = useState('');

    const workbooks = useSelector((store) => store.file.workbooks)

    useEffect(()=>{
        getWorkbooks()
    },[])

    const handleSubmit = async () => {

        if(workbook){
            const data = new FormData() 
            data.append('workbook', workbook);
            setErr("")
            
            uploadWorkbook(data).then((resData)=>{
                setWorkbook('')
                getWorkbooks()
            })
        }else{
            console.log("Error")
            setErr("All fields required!")
        }
    }


    const handleRemove = (item) => {
        removeWorkbook(item).then((resData)=>{
            getWorkbooks()
        })
    }

    return (
        <>
         <Section  title="Workbooks"> 
            <div className='col-md-12 mt-2 error' style={{"color":"red"}} > {err}</div>
            <Row>
                <div className='col-md-4'>
                    <FilePond 
                        onaddfile={(err,file) =>{
                            setWorkbook(file.file);
                        }}
                        onremovefile={(err,file) =>{
                            setWorkbook("");
                        }}
                    />
                </div> <br />
            </Row>
            <Row>
                <Col lg={3}>
                    <Button variant="success" onClick={()=> handleSubmit()}>Upload Main Sheet</Button>
                </Col>
            </Row>
            <div className='col-md-12' style={{"color":"green"}}> {response}</div>
        </Section>

        <Section  title="Workbooks List"> 
                <ul className='workbooks-list'>
                    {
                        workbooks.map( (item) =>
                            <li key={item}> {item.name}
                                <span className='wordkbook-actions'>
                                    <a className='btn btn-info' target='_blank' href={`${BASEURL}/workbooks/${item.name}`}>Download</a>
                                    <button className='btn btn-danger' onClick={()=>handleRemove(item)}>Delete</button>
                                </span>
                            </li>
                        )
                    }
                    
                </ul>
        </Section>
        </>
    )
}

export default Files;