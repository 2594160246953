import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    active: 'ocr',
    class: 'active'
  },
  reducers: {
    init: (state, action) => {
      state.active = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { init } = menuSlice.actions

export default menuSlice.reducer